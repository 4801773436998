import { createGuide, createNavigation } from "@/helpers/guideHelper.js";

export default {
	name: "PrivacyPolicy",
	metaInfo() {
		return {
			title: "Privacy Policy",
			meta: [
				{
					name: "description",
					content: 'Topic description about "Privacy Policy" page',
				},
				{
					name: "og:url",
					content: this.createCanonicalUrl(),
				},
				{
					name: "og:type",
					content: "website",
				},
				{
					name: "og:title",
					content: "Privacy Policy",
				},
				{
					name: "og:description",
					content: 'Topic description about "Privacy Policy" page',
				},
				{
					name: "og:image",
					content: require("@/assets/images/charts-with-shadow.png"),
				},
				{
					name: "twitter:card",
					content: "summary_large_image",
				},
				{
					name: "twitter:domain",
					content: "1tn.com",
				},
				{
					name: "twitter:url",
					content: this.createCanonicalUrl(),
				},
				{
					name: "twitter:title",
					content: "Privacy Policy",
				},
				{
					name: "twitter:description",
					content: 'Topic description about "Privacy Policy" page',
				},
				{
					name: "twitter:image",
					content: require("@/assets/images/charts-with-shadow.png"),
				},
			],
			link: [
				{
					rel: "canonical",
					href: this.createCanonicalUrl(),
				},
			],
		};
	},
	data: () => ({
		guide: [],
	}),
	mounted() {
		this.init();
	},
	methods: {
		async init() {
			if (!this.state.translations) return;
			this.guide = [];
			this.guide = await createGuide(
				this.state.translations,
				"privacy_policy_guide"
			);
			this.$nextTick(() => createNavigation("section-guide"));
		},
	},
	watch: {
		"state.translations": function (value) {
			if (value) this.init();
		},
	},
};
